const latencyEndpoint = "/v1/latency";
const sysConfig = {
  moduleRegister: process.env?.REACT_APP_MODULE_REGISTER === "true",
  moduleLocation: process.env?.REACT_APP_MODULE_LOCATION === "true",
  modulePayment: process.env?.REACT_APP_MODULE_PAYMENT === "true",
  moduleDebug: process.env?.REACT_APP_MODULE_DEBUG === "true",
  moduleLatency: process.env?.REACT_APP_MODULE_LATENCY === "true",
  moduleEngine: process.env?.REACT_APP_MODULE_ENGINE_PAGE === "true",
  moduleLogs: process.env.REACT_APP_MODULE_LOGS === "true",
  helpChat: process.env?.REACT_APP_HELPCHAT === "true",
  backendURL: process.env.REACT_APP_BACKENDURL,
  docsURL: process.env.REACT_APP_DOCS_URL,
  hjID: process.env.REACT_APP_HOTJAR_ID,
  hjVersion: process.env.REACT_APP_HOTJAR_VERSION,
  gtagID: process.env.REACT_APP_GTAG_ID,
  paddleID: process.env.REACT_APP_PADDLE_ID,
  paddleMode: process.env.REACT_APP_PADDLE_MODE,
  isSentryActive: process.env.REACT_APP_SENTRY_ACTIVE === "true",
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  loadTestStepCount: process.env.REACT_APP_LOAD_TEST_STEP_COUNT,
  isProduction: process.env.REACT_APP_NODE_ENV === "production",
  isSelfHosted: process.env?.REACT_APP_NODE_ENV === "onprem",
  postmanMaxRequestSelection:
    process.env.REACT_APP_POSTMAN_MAX_REQUESTS_SELECTION ?? 10,
  cloudURL: process.env.REACT_APP_CLOUD_URL,
  slackURL: process.env.REACT_APP_SLACK_REDIRECT_URL,
  slackClientID: process.env.REACT_APP_SLACK_CLIENT_ID,
  onboardingTool: process.env.REACT_APP_ONBOARDING_TOOL,
  workspaceCode: process.env.REACT_APP_WORKSPACE_CODE,
  logWebsocketUrl: process.env.REACT_APP_LOG_WEBSOCKET_URL,
  mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
  // NOTE
  // https://www.freecodecamp.org/news/how-the-question-mark-works-in-javascript/
  // ** ?? only allows undefined and null values, not empty strings ('') or 0s.

  // ** Host (Domain) Endpoints
  hostEndpoint: "/host/",
  hostCheckEndpoint: "/check/",

  // ** Test Plans Endpoints
  testPlanEndpoint: "/testplantemplate/",

  // ** Test Endpoints
  testEndpoint: "/test/",

  // ** Payment Endpoints
  paymentTransactionEndpoint: "/payment/transaction/",

  // ** Environment Endpoints
  environmentEndpoint: "/environment/",

  // ** Test Data Endpoints
  testDataEndpoint: "/upload/testdata/",

  // ** Latency Endpoint
  latencyAvailableLocations: latencyEndpoint + "/locations/",
  latencyStart: latencyEndpoint + "/test/",
  latencyTestPlan: latencyEndpoint + "/plan/",
  latencyScheduler: latencyEndpoint + "/scheduler/"
};

export default sysConfig;
