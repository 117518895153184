import useJwt from "@src/auth/jwt/useJwt";
import axios from "axios";
import jwtDefaultConfig from "./jwtDefaultConfig";

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  subscribers = [];

  jwtAxios = axios.create();

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    this.jwtAxios.defaults.baseURL = this.jwtConfig.baseURL;
    this.jwtAxios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
  }
  // ** For Refreshing Token START

  setAlreadyFetchingAccessToken(value) {
    if (value) {
      localStorage.setItem(
        this.jwtConfig.setAlreadyFetchingAccessToken,
        Date.now()
      );
    } else {
      localStorage.removeItem(this.jwtConfig.setAlreadyFetchingAccessToken);
    }
  }

  getAlreadyFetchingAccessToken() {
    const alreadyFetchingAccessToken = localStorage.getItem(
      this.jwtConfig.setAlreadyFetchingAccessToken
    );
    if (alreadyFetchingAccessToken === "true") {
      localStorage.setItem(
        this.jwtConfig.setAlreadyFetchingAccessToken,
        Date.now()
      );
      return false;
    } else if (alreadyFetchingAccessToken === "false") {
      localStorage.removeItem(this.jwtConfig.setAlreadyFetchingAccessToken);
      return false;
    } else if (10000 > Date.now() - alreadyFetchingAccessToken) {
      return true;
    }
    return false;
  }
  // ** For Refreshing Token FINISH

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    try {
      return JSON.parse(
        localStorage.getItem(this.jwtConfig.storageTokenKeyName)
      );
    } catch (e) {
      return "";
    }
  }

  getRefreshToken() {
    try {
      return JSON.parse(
        localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
      );
    } catch (e) {
      return "";
    }
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return this.jwtAxios.post(this.jwtConfig.loginEndpoint, ...args);
  }

  socialGoogleLogin(...args) {
    return this.jwtAxios.post(
      this.jwtConfig.socialGoogleLoginEndpoint,
      ...args
    );
  }

  logout(...args) {
    return useJwt
      .jwtAxios({
        method: "post",
        url: this.jwtConfig.logoutEndpoint,
        data: args?.[0]
      })
      .then(function (response) {
        return response;
      })
      .catch(function (e) {
        return e;
      });
  }

  register(...args) {
    return this.jwtAxios.post(this.jwtConfig.registerEndpoint, ...args) || null;
  }

  refreshToken() {
    return this.jwtAxios.post(this.jwtConfig.refreshEndpoint, {
      refresh: this.getRefreshToken()
    });
  }
}
