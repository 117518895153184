// ** Initial State
const initialState = {
  isZombieFilterActive: false,
  isShowUnknownConnections: false,
  isRPSFilterActive: false,
  isLatencyFilterActive: false,
  isStatusFilterActive: false,
  isProtocolFilterActive: false,
  latencyFilter: [],
  rpsFilter: [],
  searchedNodes: {},
  hoveredNode: undefined,
  httpStatuses: [1, 2, 3, 4, 5],
  protocols: [
    "POSTGRES",
    "AMQP",
    "HTTP",
    "HTTPS",
    "REDIS",
    "gRPC",
    "KAFKA",
    "MYSQL",
    "MONGO"
  ],
  allProtocols: [
    "HTTP",
    "HTTPS",
    "POSTGRES",
    "AMQP",
    "REDIS",
    "gRPC",
    "KAFKA",
    "MYSQL",
    "MONGO"
  ],
  allHttpStatuses: [1, 2, 3, 4, 5],
  distTracingFilter: false,
  monitoringActiveTab: "0",
  selectedNode: null,
  selectedNodes: []
};

const ServiceMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILTER_DATA":
      return {
        ...state,
        ...action.value
      };
    case "SET_SEARCHED_NODES":
      return {
        ...state,
        searchedNodes: action.value
      };
    case "SET_HOVERED_NODE":
      return {
        ...state,
        hoveredNode: action.value
      };
    case "RESET_FILTERS":
      return {
        ...state,
        ...initialState
      };
    case "DIST_TRACING_FILTER":
      return {
        ...state,
        distTracingFilter: action.value
      };
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        monitoringActiveTab: action.value
      };
    case "SET_SELECTED_NODES":
      let selectedNodes = state.selectedNodes.filter(
        (node) => node.id !== action.value.id
      );
      selectedNodes.push(action.value);
      return {
        ...state,
        selectedNodes,
        selectedNode: action.value
      };
    case "SET_SELECTED_NODES_CTRL":
      let selectedNodesCTRL = state.selectedNodes.filter(
        (node) => node.id !== action.value.id
      );
      selectedNodesCTRL.push(action.value);
      return {
        ...state,
        selectedNodes: selectedNodesCTRL
      };
    case "REMOVE_NODE_SELECTION":
      let newActiveIndex = state.selectedNodes.findIndex(
        (obj) => obj.id === action.value.id
      );

      if (state.selectedNode?.id === action.value?.id) {
        newActiveIndex = newActiveIndex - 1;
      }

      const removedArray = state.selectedNodes.filter(
        (node) => node.id !== action.value.id
      );
      let newSelected = null;
      if (state.selectedNode && removedArray.length > 0) {
        newActiveIndex = Math.max(newActiveIndex, 0);
        newActiveIndex = Math.min(newActiveIndex, removedArray.length - 1);
        newSelected = removedArray[newActiveIndex];
      }

      const newState = {
        ...state,
        selectedNodes: removedArray,
        selectedNode: newSelected
      };
      return newState;
    case "HANDLE_NODE_TAB":
      return {
        ...state,
        selectedNode: action.value
      };
    default:
      return state;
  }
};

export default ServiceMapReducer;
